import React, { useState } from 'react'
import m from 'moment'

const DateDropdown = () => {
    const [date, setDate] = useState({ day: 1, month: 1, year: 2000 })

    const daysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate()
    }

    const handleDayChange = (e) => {
        setDate({ ...date, day: parseInt(e.target.value) })
    }

    const handleMonthChange = (e) => {
        setDate({ ...date, month: parseInt(e.target.value) })
    }

    const handleYearChange = (e) => {
        setDate({ ...date, year: parseInt(e.target.value) })
    }

    const generateOptions = (from, to) => {
        return Array.from({ length: to - from + 1 }, (_, i) => i + from).map(
            (num) => (
                <option key={num} value={num}>
                    {num.toString().padStart(2, '0')}
                </option>
            )
        )
    }

    const ui = (
        <div>
            <select onChange={handleDayChange} value={date.day}>
                {generateOptions(1, daysInMonth(date.month, date.year)).map(
                    (opt) => opt
                )}
            </select>
            <select onChange={handleMonthChange} value={date.month}>
                {generateOptions(1, 12).map((opt) => opt)}
            </select>
            <select onChange={handleYearChange} value={date.year}>
                {generateOptions(1900, m().year()).map((opt) => opt)}
            </select>
        </div>
    )

    return { ui, date }
}

export default DateDropdown
