import React, { useState } from 'react'
import { Input, Button, message } from 'antd'
import axios from 'axios'
import Center from './Center'
import request from '../request'

const ConnectPage = ({ setShowInscriptionForm, password, setPassword }) => {
    const handleConnect = async (e) => {
        e.preventDefault() // Prevent default form submission behavior
        try {
            const response = await request.post(
                '/connect',
                {},
                {
                    headers: {
                        password: password,
                    },
                }
            )
            const { success } = response.data

            if (success) {
                setShowInscriptionForm(true)
            } else {
                message.error('Mot de passe incorrect')
            }
        } catch (error) {
            message.error('Mot de passe incorrect')
        }
    }

    return (
        <div style={{ maxWidth: '300px', margin: 'auto', marginTop: '20px' }}>
            <h1>Connexion</h1>
            <form onSubmit={handleConnect}>
                {' '}
                {/* Add the form element */}
                <Input
                    type="password"
                    placeholder="Enter le mot de passe"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Center>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{ marginTop: '10px' }}
                    >
                        Se connecter
                    </Button>
                </Center>
            </form>
        </div>
    )
}

export default ConnectPage
