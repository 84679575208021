import React, { useEffect, useRef, useState } from 'react'
import { Form, Input, Button, Checkbox, Radio, Col, Row, message } from 'antd'
import logo from './logo.png'
import ConnectPage from './components/Connect'
import Center from './components/Center'
import ChessWebAPI from 'chess-web-api'
import { Chessboard } from 'react-chessboard'
import SignatureCanvas from 'react-signature-canvas'
import api from './request'
import DateDropdown from './components/DatePicker'

// Create a function to calculate the age with day month and year as parameters. This param will be an object with keys day, month and year
function calculateAge({ day, month, year }) {
    const currentDate = new Date()
    const birthDate = new Date(year, month - 1, day)
    const age = currentDate.getFullYear() - birthDate.getFullYear()

    if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
            currentDate.getDate() < birthDate.getDate())
    ) {
        return age - 1
    }

    return age
}

function App() {
    const [password, setPassword] = useState('')
    const [isMinor, setIsMinor] = useState(false)
    const [showInscriptionForm, setShowInscriptionForm] = useState(false)
    const [submitted, setSubmitted] = useState(false) // New state for submission status
    const [chessPuzzle, setChessPuzzle] = useState(null)
    const [signature, setSignature] = useState('')

    const canvasRef = useRef(null)

    const { ui: DateBirthDropDown, date } = DateDropdown()

    // Utility function to calculate age

    useEffect(() => {
        const chessAPI = new ChessWebAPI({
            queue: true,
        })

        chessAPI.getDailyPuzzleRandom().then((response) => {
            const { body } = response
            setChessPuzzle(() => body.fen)
        })
    }, [])

    useEffect(() => {
        const age = calculateAge(date)
        setIsMinor(age < 18)
    }, [date])

    const onFinish = async (values) => {
        // Create a new FormData instance
        const formData = new FormData()

        const data = JSON.stringify(values)
        // Append all form values to the FormData instance
        formData.append('info', data)
        // Append the signature blob to the FormData
        formData.append('signature', signature)
        formData.append(
            'dateDeNaissance',
            `${date.year}-${date.month}-${date.day}`
        )

        for (const [key, value] of formData.entries()) {
            console.log(key, value)
        }
        try {
            const response = await api.post('/inscription', formData, {
                headers: { 'Content-Type': 'multipart/form-data', password },
            })
            const { success } = response.data

            if (success) {
                setSubmitted(true)
            } else {
                message.error("Erreur lors de l'envoi du formulaire.")
            }

            message.success('Votre inscription est envoyée, merci!')
        } catch (error) {
            message.error("Erreur lors de l'envoi du formulaire.")
        }
    }

    return (
        <div style={{ maxWidth: '800px', margin: 'auto' }}>
            <div style={{ textAlign: 'center' }}>
                <img
                    src={logo}
                    alt="Description"
                    style={{ width: '150px', margin: '20px 0' }}
                />
            </div>

            <div>
                {showInscriptionForm ? (
                    <>
                        {submitted ? ( // Render success message if submitted
                            <div style={{ textAlign: 'center' }}>
                                <h2>Votre inscription est envoyée, merci!</h2>
                            </div>
                        ) : (
                            // Render the form if not submitted
                            <>
                                <h1>Formulaire d'inscription</h1>
                                <Form
                                    onFinish={onFinish}
                                    labelAlign="right" // Align labels at the top
                                    labelCol={{ flex: '0 0 150px' }} // Set label width
                                >
                                    <Row gutter={16}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="nom"
                                                label="Nom"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Veuillez entrer votre nom.',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="prenom"
                                                label="Prénom"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Veuillez entrer votre prénom.',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="dateDeNaissance"
                                                label="Date de Naissance"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Veuillez entrer votre date de naissance.',
                                                    },
                                                ]}
                                            >
                                                {DateBirthDropDown}
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="nationality"
                                                label="Nationalité"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Veuillez entrer votre nationalité.',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Row gutter={16}></Row>

                                    <Row gutter={16}>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="portable"
                                                label="Portable"
                                            >
                                                <Input type="phone" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                name="mail"
                                                label="Email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Veuillez entrer votre adresse mail.',
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Center>
                                        <h3>Cession de droit a l'image</h3>
                                    </Center>
                                    <Center>
                                        <Form.Item
                                            name="photoAuthorization"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Veuillez faire un choix.',
                                                },
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Radio value={true}>
                                                    Je donne mon accord pour
                                                    apparaitre sur les réseaux.
                                                </Radio>
                                                <Radio value={false}>Non</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Center>

                                    {isMinor && (
                                        <>
                                            <h2>Informations des parents</h2>
                                            <h3>Père</h3>
                                            <Row gutter={16}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        name={[
                                                            'parentInfo',
                                                            'pere',
                                                            'nom',
                                                        ]}
                                                        label="Nom du père"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        name={[
                                                            'parentInfo',
                                                            'pere',
                                                            'prenom',
                                                        ]}
                                                        label="Prénom du père"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={16}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        name={[
                                                            'parentInfo',
                                                            'pere',
                                                            'telephone',
                                                        ]}
                                                        label="Téléphone du père"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        name={[
                                                            'parentInfo',
                                                            'pere',
                                                            'profession',
                                                        ]}
                                                        label="Profession du père"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <h3>Mère</h3>
                                            <Row gutter={16}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        name={[
                                                            'parentInfo',
                                                            'mere',
                                                            'nom',
                                                        ]}
                                                        label="Nom de la mère"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        name={[
                                                            'parentInfo',
                                                            'mere',
                                                            'prenom',
                                                        ]}
                                                        label="Prénom de la mère"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        name={[
                                                            'parentInfo',
                                                            'mere',
                                                            'telephone',
                                                        ]}
                                                        label="Téléphone de la mère"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        name={[
                                                            'parentInfo',
                                                            'mere',
                                                            'profession',
                                                        ]}
                                                        label="Profession de la mère"
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={16}>
                                                <Col xs={24} md={12}>
                                                    <Form.Item
                                                        name="autorisationDepartSeul"
                                                        valuePropName="checked"
                                                    >
                                                        <Checkbox>
                                                            J'autorise mon
                                                            enfant à partir seul
                                                        </Checkbox>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12}>
                                                    <div />
                                                </Col>
                                            </Row>
                                        </>
                                    )}
                                    <h2>
                                        Informations de la personne à contacter
                                        en cas d'urgence
                                    </h2>
                                    <Form.Item
                                        name={['emergencyContact', 'nom']}
                                        label="Nom d'urgence"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Veuillez entrer le nom.',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={['emergencyContact', 'prenom']}
                                        label="Prénom d'urgence"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Veuillez entrer le prénom.',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name={['emergencyContact', 'telephone']}
                                        label="Téléphone d'urgence"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Veuillez entrer le numéro de telephonne.',
                                            },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <h2>Informations sur la licence</h2>
                                    <Center>
                                        <Form.Item
                                            name="licenseType"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Veuillez selectionner votre licence.',
                                                },
                                            ]}
                                        >
                                            <Radio.Group>
                                                <Radio value="A">
                                                    Licence A (90 euros)
                                                </Radio>
                                                <Radio value="B">
                                                    Licence B (70 euros)
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Center>
                                    <h2>Signature</h2>
                                    <Center>
                                        <div
                                            style={{
                                                border: '1px solid black',
                                                display: 'inline-block',
                                            }}
                                        >
                                            <SignatureCanvas
                                                penColor="black"
                                                canvasProps={{
                                                    width: 300,
                                                    height: 200,
                                                    className: 'sigCanvas',
                                                }}
                                                ref={canvasRef}
                                                onEnd={() => {
                                                    canvasRef.current
                                                        .getTrimmedCanvas()
                                                        .toBlob((blob) => {
                                                            setSignature(blob)
                                                            console.log(blob)
                                                        }, 'image/png')
                                                }}
                                            />
                                        </div>
                                    </Center>

                                    <Button
                                        style={{ marginTop: 5 }}
                                        onClick={() => {
                                            canvasRef.current.clear()
                                        }}
                                    >
                                        Effacer la signature
                                    </Button>

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            style={{
                                                marginTop: 20,
                                                width: '100%',
                                            }}
                                        >
                                            Envoyer
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Center>
                            <ConnectPage
                                setShowInscriptionForm={setShowInscriptionForm}
                                password={password}
                                setPassword={setPassword}
                            />
                        </Center>
                        <Center>
                            <div style={{ width: 400, marginTop: 5 }}>
                                {!!chessPuzzle ? (
                                    <Chessboard position={chessPuzzle} />
                                ) : (
                                    <Chessboard position="start" />
                                )}
                            </div>
                        </Center>
                    </>
                )}
            </div>
        </div>
    )
}

export default App
